<template>
    <div>
      <b-container class="mt-5 mb-5">
        <template v-if="!order.canceled_at">
          <b-dropdown dropleft variant="outline-dark" text="Opties" class="float-right">
            <b-dropdown-item @click="resendTickets(order)">Tickets opnieuw versturen</b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item variant="danger" @click="cancelOrder(order)">Bestelling annuleren</b-dropdown-item>
          </b-dropdown>
        </template>
        <h1 class="fs-4 font-weight-bold mb-4">Bestelling {{ order.id }}</h1>

        <b-alert variant="danger" v-if="order.canceled_at" show>
          Deze bestelling is geannuleerd op {{ order.canceled_at|formatDateTime }}
        </b-alert>
  
        <b-card class="mb-3" header="Tickets" header-tag="h4">
            <table class="table table-striped">
                <thead>
                <tr class="text-muted">
                    <th>Barcode</th>
                    <th>Event</th>
                    <th>Startdatum</th>
                    <th>Klant</th>
                    <th>E-mail</th>
                    <th>Status</th>
                    <th>Datum</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(ticket,key) in tickets" :key="key">
                    <td>{{ ticket.barcode }}</td>
                    <td>{{ ticket.event.name }}</td>
                    <td>{{ ticket.date.start_at }}</td>
                    <td>{{ ticket.firstname }} {{ ticket.lastname }}</td>
                    <td>{{ ticket.email }}</td>
                    <td>
                        <template v-if="ticket.canceled_at">
                            <b-badge variant="danger" class="bg-danger">
                            Geannuleerd
                            </b-badge>
                        </template>
                        <template v-else>
                            <b-badge variant="success" class="bg-success">
                            Actief
                            </b-badge>
                        </template>
                    </td>
                    <td>{{ ticket.created_at.date|formatDateTime }}</td>
                </tr>
                </tbody>
            </table>
        </b-card>

        <b-row>
          <b-col md="6">
            <b-card class="mb-3" header="Bestelgegevens" header-tag="h4">
              <table class="table table-striped">
                <tbody>
                  <!--
                  <tr>
                    <td>Evenement datum</td>
                    <td>XXX</td>
                  </tr>
                  -->
                  <tr>
                    <td>Besteldatum</td>
                    <td>{{ order.created_at.date|formatDateTime }}</td>
                  </tr>
                  <tr>
                    <td>Bestelnr.</td>
                    <td>{{ order.id }}</td>
                  </tr>
                  <tr>
                    <td>Status</td>
                    <td>
                      <template v-if="order.canceled_at">
                        <b-badge variant="danger" class="bg-danger">
                          Geannuleerd
                        </b-badge>
                      </template>
                      <template v-else>
                        <template v-if="order.payment_status == 'paid'">
                          <b-badge variant="success" class="bg-success">
                            Betaald
                          </b-badge>
                        </template>
                        <template v-if="order.payment_status == 'open' || order.payment_status == 'pending' || order.payment_status == 'created'">
                          <b-badge variant="warning" class="bg-warning">
                            Open
                          </b-badge>
                        </template>
                        <template v-if="order.payment_status == 'expired'">
                          <b-badge variant="danger" class="bg-danger">
                            Verlopen
                          </b-badge>
                        </template>
                        <template v-if="order.payment_status == 'canceled'">
                          <b-badge variant="danger" class="bg-danger">
                            Geannuleerd
                          </b-badge>
                        </template>
                      </template>
                    </td>
                  </tr>
                  <tr>
                    <td>Betaalmethode</td>
                    <td>{{ order.payment_provider }}</td>
                  </tr>
                  <tr>
                    <td>Kosten</td>
                    <td>{{ order.total_price|toCurrency }}</td>
                  </tr>
                  <tr>
                    <td>Verwijzing</td>
                    <td>Webshop</td>
                  </tr>
                </tbody>
              </table>
            </b-card>
          </b-col>
          <b-col md="6">
            <b-card class="mb-3" header="Klantgegevens" header-tag="h4">
              <table class="table table-striped">
                <tbody>
                  <tr>
                    <td>Naam</td>
                    <td>{{ order.customer.firstname }} {{ order.customer.lastname }}</td>
                  </tr>
                  <tr>
                    <td>Geslacht</td>
                    <td>{{ order.customer.gender }}</td>
                  </tr>
                  <tr>
                    <td>E-mailadres</td>
                    <td>{{ order.customer.email }}</td>
                  </tr>
                  <tr>
                    <td>Postcode</td>
                    <td>{{ order.customer.zipcode }}</td>
                  </tr>
                  <tr>
                    <td>Adres</td>
                    <td>{{ order.customer.address }}</td>
                  </tr>
                  <tr>
                    <td>Plaats</td>
                    <td>{{ order.customer.city }}</td>
                  </tr>
                  <tr>
                    <td>Telefoonnummer</td>
                    <td>{{ order.customer.phone }}</td>
                  </tr>
                </tbody>
              </table>
            </b-card>
          </b-col>
        </b-row>

        <b-card class="mb-3" header="Historie" header-tag="h4"></b-card>
        

      </b-container>
    </div>
  </template>

<script>
  import { mapActions } from 'pinia'
  import { useShopStore } from '@/stores/shop'

  export default {
      metaInfo: {
          title: 'Tickets',
      },
      components: {},
      data() {
          return {
            order: {},
            tickets: []
          }
      },
      computed: {},
      methods: {
        ...mapActions(useShopStore, ['setLoading', 'isAdmin']),
        getTickets() {
          this.setLoading(true);
          this.$axios.get("https://api.tixgo.nl/orders/" + this.$route.params.id + '/tickets')
            .then(response => {
              this.tickets = response.data;
              this.setLoading(false);
            }
          ).catch((reason) => {
            console.log(reason);
          });
        },
        getOrder: function() {
          this.setLoading(true);
          this.$axios.get("https://api.tixgo.nl/orders/" + this.$route.params.id)
            .then( response => {
                this.order = response.data;
                this.setLoading(false);
            }
          ).catch((reason) => {
              console.log(reason);
          });
        },
        cancelOrder: function(order) {
          if( confirm("Weet je zeker dat je deze bestelling wilt annuleren?") ) {
                this.$axios.post("https://api.tixgo.nl/orders/" + order.id + '/cancel')
                    .then( response => {
                        response;
                        this.$router.push('/manage/events');
                    })
                ;
            }
        },
        resendTickets: function(order) {
          if( confirm("Weet je zeker dat je de tickets opnieuw wilt versturen?") ) {
                this.$axios.post("https://api.tixgo.nl/orders/" + order.id + '/resend')
                    .then( response => {
                        response;
                        this.$router.push('/manage/events');
                    })
                ;
            }
        },
      },
      created() {
        this.getOrder();
        this.getTickets();
      }
  }
</script>